










import { onMounted, defineComponent, onUnmounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const showButton = ref(false)

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleScroll = () => {
      if (window.scrollY > 20) {
        showButton.value = true
      } else {
        showButton.value = false
      }
    }

    return {
      scrollToTop,
      showButton,
    }
  },
})
