import { defineStore } from 'pinia'
import { normalizeImage, generateImageUrl } from './utils'
import { DEVKEY } from '@/common/constants/cms'
import type {
  Maybe,
  Link,
  GlobalHeaderImages,
  GlobalHeaderTopBar,
  GlobalFooterSocialSocialNetworks,
  GlobalFooterImages,
  FetchGlobalHeaderAndFooterByUidQuery,
} from '~/modules/contentstack/types'
import { useCountry } from '~/composables/country/useCountry'

interface CMSLayoutStore {
  // Header stuff
  topbar: Maybe<GlobalHeaderTopBar>
  headerBrandLogo?: { image: ReturnType<typeof normalizeImage>; link: Maybe<Link> }
  percentLogo?: { image: ReturnType<typeof normalizeImage>; link: Maybe<Link> }
  notification: Record<string, any>
  showMainNav: boolean
  // Footer stuff
  copyrightRte?: any
  footerBrandLogo?: { image: ReturnType<typeof normalizeImage>; link: Maybe<Link> }
  backgroundImageId: String
  social: Maybe<Array<Maybe<GlobalFooterSocialSocialNetworks>>>
}

export const useStore = defineStore({
  id: 'cms-layout',
  state: (): CMSLayoutStore => ({
    topbar: {},
    headerBrandLogo: undefined,
    percentLogo: undefined,
    notification: {},
    showMainNav: false,
    copyrightRte: undefined,
    footerBrandLogo: undefined,
    backgroundImageId: '',
    social: [],
  }),
  actions: {
    setShowMainNav(val: boolean) {
      this.showMainNav = val
    },
    async fetchHeaderAndFooter(headerUid: string, footerUid: string) {
      const { getLocaleCode } = useCountry()
      const locale = getLocaleCode()
      const { data } = await this.$nuxt.$cms.api.fetchGlobalHeaderAndFooter({
        headerUid,
        footerUid,
        headerLocale: locale,
        footerLocale: locale,
        footerFallbackLocale: true,
        headerFallbackLocale: true,
      })

      this.setLayoutData(data)
    },
    setLayoutData(data: FetchGlobalHeaderAndFooterByUidQuery) {
      const headerBrandLogo = data.global_header?.images?.find((image) => {
        return image && image.devkey === DEVKEY.LOGO
      })

      const percentLogo = data.global_header?.images?.find((image) => {
        return image && image.devkey === DEVKEY.LOGO_1_PERCENT
      })

      const footerBrandLogo = data.global_footer?.images?.find((image) => image?.devkey === DEVKEY.LOGO)
      const backgroundImage = data.global_footer?.images?.find(
        (image) => image && image.devkey === DEVKEY.COPYRIGHT_BACKGROUND
      )

      this.$patch({
        topbar: data.global_header?.top_bar,
        headerBrandLogo: headerBrandLogo ? normalizeLogo(headerBrandLogo) : undefined,
        percentLogo: percentLogo ? normalizeLogo(percentLogo) : undefined,
        copyrightRte: data.global_footer?.copyright_rte?.json,
        footerBrandLogo: footerBrandLogo ? normalizeLogo(footerBrandLogo) : undefined,
        backgroundImageId: backgroundImage ? generateImageUrl({ imageId: backgroundImage?.image?.cloudflare_id }) : '',
        social: data.global_footer?.social?.social_networks,
      })
    },
  },
})

const normalizeLogo = (logo: GlobalHeaderImages | GlobalFooterImages) => {
  return {
    image: normalizeImage(logo.image),
    link: logo.link,
  }
}
