import { useCountry } from '../country/useCountry'
import { useCMSDynadminStore } from '~/store'

export function isProductExcludedFromSale(product?: { sku: string; price?: any }) {
  const { isUS, isJapan } = useCountry()
  const { excludeProductsUS, excludeProductsJP } = useCMSDynadminStore()

  const productId = product?.sku.slice(0, -1) ?? ''

  return (
    (isUS.value && excludeProductsUS.includes(productId)) ||
    (isJapan.value && excludeProductsJP.includes(productId)) ||
    !product?.price?.value?.centAmount
  )
}
