import { Context } from '@nuxt/types'
import { getEmailDomain } from './characters'
import { getTestingIdCookie } from '~/common/utils/testingIdCookie'
import { EvaluateOption } from '~/types/featureFlags/featureFlagsPlugin'

type CreateEvidentlyRequestParams = {
  featureName: string
  evaluateOption: EvaluateOption
  context: Context
  email: string
  countryCode: string
}

export function createEvidentlyRequest({
  featureName,
  evaluateOption,
  context,
  email,
  countryCode,
}: CreateEvidentlyRequestParams) {
  let entityId: string
  const testingId = getTestingIdCookie(context)

  if (testingId) {
    entityId = testingId
  } else if (evaluateOption === 'email') {
    entityId = getEmailDomain(email) || 'anonymous'
  } else if (evaluateOption === 'country') {
    entityId = countryCode
  } else {
    entityId = 'anonymous'
  }

  return {
    feature: featureName,
    entityId,
  }
}
