























import { defineComponent } from '@nuxtjs/composition-api'

import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user'

export default defineComponent({
  setup(_, { emit }) {
    const { selectedLanguage, selectedCountry } = storeToRefs(useUserStore())
    const langCodeMap: Record<string, { display: string; value: string }[]> = {
      JP: [
        { display: 'EN', value: 'en' },
        { display: 'JP', value: 'jp' },
      ],
    }

    const changeLanguage = (e: any) => {
      emit('language-change', { language: e.target.value })
    }

    return { changeLanguage, selectedLanguage, langCodeMap, selectedCountry }
  },
})
