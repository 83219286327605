import { Context } from '@nuxt/types'
import { useContext, computed } from '@nuxtjs/composition-api'
import { useFeatureFlag } from '../featureFlags/useFeatureFlag'
import { useUserStore } from '~/store'
import { useCMSDynadminStore } from '~/store/cms'
import { useCountry } from '../country/useCountry'

export function useQuotePageType(ctx?: Context) {
  const context = ctx ?? useContext()
  const userStore = useUserStore(context.$pinia)
  const cmsStore = useCMSDynadminStore(context.$pinia)
  const { isQuotesCountry } = useCountry()

  return computed(() => {
    if (cmsStore.b2dAllowedAddToBasket.map((c) => c.toLowerCase()).includes(userStore.selectedCountry.toLowerCase())) {
      return 'lead'
    }
    if (isQuotesCountry.value) {
      return 'quote'
    }
    return null
  })
}
