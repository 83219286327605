import { CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { Client } from '../../client'
import {
  hasToken,
  hasRefreshToken,
  getBearerAuthorizationHeader,
  setAuthHeaders,
  hasPunchoutCustomer,
} from '../../util'

import {
  URL_CLIENT_TOKEN,
  URL_ANONYMOUS_TOKEN,
  URL_CUSTOMERS_TOKEN,
  URL_LOGIN,
  URL_SIGNUP,
  URL_PASSWORD_RESET,
  URL_PASSWORD_CHANGE,
  URL_CARTS,
} from '../../util/constants'

export default (client: Client) => {
  const authClient = client.auth.client
  const authCookieStore = client.cookies.auth
  const customerCookieStore = client.cookies.customer

  return {
    async onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }

      if (
        !hasToken(authCookieStore) ||
        (!hasRefreshToken(authCookieStore) &&
          !hasPunchoutCustomer(customerCookieStore) &&
          config.url?.startsWith(URL_CARTS))
      ) {
        console.debug('CTC: FETCH Token - start')

        try {
          if (config.url?.startsWith(URL_CARTS)) {
            await authClient.fetchAnonymousToken()
          } else {
            await authClient.fetchClientCredentialsToken()
          }
          setAuthHeaders({ config, authCookieStore })
          return config
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      if (hasToken(authCookieStore)) {
        config.headers = config.headers || {}
        config.headers.Authorization = getBearerAuthorizationHeader(authCookieStore)
      }

      return config
    },
    async onResponse(response: CustomAxiosResponse) {
      const { config } = response
      if (config.url && [URL_LOGIN, URL_SIGNUP].includes(config.url)) {
        const data = typeof config.data === 'string' ? JSON.parse(config.data) : config.data

        try {
          await authClient.fetchCustomerToken(data.email, data.password)
          return response
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      if (config.url && [URL_PASSWORD_RESET, URL_PASSWORD_CHANGE].includes(config.url)) {
        client.clearCookies()
      }

      return response
    },
  }
}
