










import { defineComponent, ref } from '@nuxtjs/composition-api'

import CountryCode from './CountryCode.vue'
import LanguageSelector from './LanguageSelector.vue'
import CountryCodeModal from './CountryCodeModal.vue'
import { useCountrySelector } from '~/composables/countrySelector'

export default defineComponent({
  components: {
    CountryCode,
    LanguageSelector,
    CountryCodeModal,
  },

  setup() {
    const isModalOpen = ref(false)
    const { changeCountry, changeLanguage } = useCountrySelector()

    const setModal = (value: boolean = true) => {
      isModalOpen.value = value
    }

    return {
      isModalOpen,
      setModal,
      changeCountry,
      changeLanguage,
    }
  },
})
