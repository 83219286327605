import { Client } from '../client'
import {
  URL_LOGIN,
  URL_SIGNUP,
  URL_REVOKE_TOKEN,
  URL_REQUEST_PASSWORD_RESET,
  URL_PASSWORD_RESET,
  URL_PASSWORD_CHANGE,
  URL_VERIFY_EMAIL_TOKEN,
  URL_RESEND_VERIFICATION_EMAIL,
  AUTH_STORE_FIELD,
} from '../util/constants'

type LoginPayload = {
  username: string
  password: string
}

type SignUpCustomFields = {
  isNewsletterOptin: boolean
  privacyAgreedToDateTime: string
}

type SignUpCustom = {
  fields: SignUpCustomFields
}

type SignUpPayload = {
  email: string
  password: string
  firstName: string
  lastName: string
  companyName: string
  country: string
  vatId?: string
  recaptchaToken: string
  custom: SignUpCustom
}

export const bindClient = (client: Client) => {
  const login = (params: LoginPayload): Promise<any> => {
    const data = { email: params.username, password: params.password }
    return client.axios.post(URL_LOGIN, data)
  }

  const logout = (): Promise<any> => {
    const accessToken = client.cookies.auth.get(AUTH_STORE_FIELD.ACCESS_TOKEN)
    return client.auth.client.revokeToken(accessToken as string)
  }

  const signup = (params: SignUpPayload) => {
    const data = {
      email: params.email,
      password: params.password,
      firstName: params.firstName,
      lastName: params.lastName,
      companyName: params.companyName,
      recaptchaToken: params.recaptchaToken,
      vatId: params.vatId ?? null,
      custom: {
        type: {
          typeId: 'type',
          key: 'regular-customer-type',
        },
        fields: {
          registrationOrigin: params.country,
          isNewsletterOptin: params.custom.fields.isNewsletterOptin,
          forcedPasswordReset: false,
          privacyAgreedToDateTime: params.custom.fields.privacyAgreedToDateTime,
        },
      },
    }

    return client.axios.post(URL_SIGNUP, data)
  }

  const verifyEmailToken = (params: { token: string }): Promise<any> => {
    // console.log('::::::: Verify Email Token Payload :::::::')
    // console.log(params)
    const data = { tokenValue: params.token }
    return client.axios.post(URL_VERIFY_EMAIL_TOKEN, data)
  }

  const resendVerificationEmail = (params: { email: string }): Promise<any> => {
    // console.log('::::::: Resend Verification Email Payload :::::::')
    // console.log(params)
    const data = { email: params.email }
    return client.axios.post(URL_RESEND_VERIFICATION_EMAIL, data)
  }

  const requestPasswordReset = (params: { email: string }): Promise<any> => {
    // console.log('::::::: Request Password Request Payload :::::::')
    // console.log(params)
    const data = {
      email: params.email,
    }
    return client.axios.post(URL_REQUEST_PASSWORD_RESET, data)
  }

  const resetPassword = (params: { token: string; password: string }): Promise<any> => {
    // console.log('::::::: Reset Password Request Payload :::::::')
    // console.log(params)
    const data = {
      tokenValue: params.token,
      newPassword: params.password,
    }
    return client.axios.post(URL_PASSWORD_RESET, data)
  }

  const changePassword = (params: { oldPassword: string; newPassword: string }): Promise<any> => {
    // console.log('::::::: Change Password Request Payload :::::::')
    // console.log(params)
    const data = {
      currentPassword: params.oldPassword,
      newPassword: params.newPassword,
    }
    return client.axios.post(URL_PASSWORD_CHANGE, data)
  }

  return {
    login,
    logout,
    signup,
    verifyEmailToken,
    resendVerificationEmail,
    requestPasswordReset,
    resetPassword,
    changePassword,
  }
}
