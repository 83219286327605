import { PRODUCT_CATEGORIES } from '../utils/product/constants'

// CONSTANT
export const SEARCH_TYPES = {
  RESOURCE: 'resources',
  PRODUCT: 'product',
  CATEGORIES: 'categories',
  HISTORY: 'history',
  SUGGESTIONS: 'suggestions',
  KNOWLEDGE_BASE: 'knowledge-base',
} as const

export const SEARCH_RESULT_FIELDS_PRODUCT = {
  productnumber: { raw: {} },
  clickurl: { raw: {} },
  categories: { raw: {} },
  name: { raw: {}, snippet: {} },
  namenotags: { raw: {}, snippet: {} },
  applications: { raw: {} },
  numberofchiclets: { raw: {} },
  speciesreactivity: { raw: {} },
  speciesreactivitycodes: { raw: {} },
  chiclets: { raw: {} },
  status: { raw: {} },
  releaseddate: { raw: {} },
  numberofapplications: { raw: {} },
  totalcitationcount: { raw: {} },
  producttype: { raw: {} },
}

export const SEARCH_RESULT_FIELDS_RESOURCES = {
  url: { raw: {} },
  title: { raw: {}, snippet: {} },
  meta_description: { raw: {} },
  url_path: { raw: {} },
  headings: { raw: {} },
}

export const NESTED_FACET_NAMES = {
  other: {
    key: 'other',
    value: 'Other',
  },
}

export const STOP_WORDS = ['antibody', 'anti-', 'anti', 'mab', 'and', '&', 'in']

export const RESULTS_PER_PAGE_NUMBERS = [30, 60, 100, 200]

const searchRedirects = {
  simpleWestern: {
    applications: 'Simple Western™',
  },
  signalStar: {
    categories: [
      PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS,
      PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS_HTML,
    ],
  },
}
export const SEARCH_REDIRECT_PHRASES = {
  'protein simple': searchRedirects.simpleWestern,
  proteinsimple: searchRedirects.simpleWestern,
  'simple protein': searchRedirects.simpleWestern,
  simpleprotein: searchRedirects.simpleWestern,
  simplewestern: searchRedirects.simpleWestern,
  westernsimple: searchRedirects.simpleWestern,
  'simple western': searchRedirects.simpleWestern,
  'western simple': searchRedirects.simpleWestern,
  'signal star': searchRedirects.signalStar,
}
