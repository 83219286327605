import { EvaluationResult } from '@aws-sdk/client-evidently'
import { FetchFeatureResult, FetchManyOptions } from '~/types/featureFlags/featureFlagsPlugin'

export function mapBatchEvaluateResult(
  data: EvaluationResult[],
  featuresToFetch: FetchManyOptions
): FetchFeatureResult[] {
  const res: FetchFeatureResult[] = []
  for (let i = 0; i < data.length; i++) {
    const feature = featuresToFetch[i]
    const result = data[i]

    // The `feature` field is an ARN, so extract only the feature name, which is a last part of the ARN
    const featureName = result.feature?.split('/').at(-1)!

    const value = Object.values(result.value ?? {})[0] // The response contains an object with a single value, which contains the flag's value
    if (value === undefined) {
      console.warn(
        `Feature ${featureName}, evaluated based on: ${feature.evaluateBasedOn} was not found. ` +
          'Using null as a fallback value.'
      )
    }

    res.push({
      name: featureName,
      value: value ?? null,
      evaluateBasedOn: feature.evaluateBasedOn,
    })
  }

  return res
}
