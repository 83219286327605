import https from 'https'
import cloneDeep from 'lodash/fp/cloneDeep'
import isEmpty from 'lodash/fp/isEmpty'
import { stringifyQuery } from 'ufo'
import { Context, Plugin } from '@nuxt/types'
import { detectServerBaseUrl } from '@cstweb/common'

const FROM_CLIENT = {
  SERVER: 'nuxt:server',
  BROWSER: 'nuxt:browser',
}

const createPlugin = ({ req, $axios }: Context) => {
  let httpsAgent, host

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
    httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    })
  }

  if (process.client) {
    host = location.origin
  }

  const axios = $axios.create({
    baseURL: `${host}/api/log`,
    httpsAgent,
  })

  // TODO: Add typings for e below
  const sendError = (e: any): Promise<any> => {
    // console.log('🚀 ~ sendError ~ e:', e)

    // TODO: Add typings for LogError below
    const error = cloneDeep(e)
    delete error?.config?.httpsAgent

    error.status = e.status ?? e.response?.status ?? e.request?.status
    error.fromClient = process.client ? FROM_CLIENT.BROWSER : FROM_CLIENT.SERVER

    if (e.config) {
      if (e.config.params) {
        if (e.config.params instanceof URLSearchParams) {
          error.urlQueryString = e.config.params.toString()
        } else {
          error.urlQueryString = stringifyQuery(e.config.params)
        }
      }

      error.request = {
        headers: cloneDeep(e.config?.headers),
        status: e.request?.status,
        data: cloneDeep(e.config?.data),
      }
      error.response = {
        headers: cloneDeep(e.response?.headers),
        status: e.response?.status,
        data: cloneDeep(e.response?.data),
      }
    }

    // console.log('🚀 ~ sendError ~ error:', error)
    // console.dir(error)
    return axios.post('/error', error)
  }

  const sendInfo = (i: any): Promise<any> => {
    // console.log('🚀 ~ sendInfo ~ i:', i)

    const info = cloneDeep(i)
    delete info?.config?.httpsAgent

    info.status = i.status ?? i.response?.status ?? i.request?.status
    info.fromClient = process.client ? FROM_CLIENT.BROWSER : FROM_CLIENT.SERVER

    if (i.config) {
      if (i.config.params) {
        if (i.config.params instanceof URLSearchParams) {
          info.urlQueryString = i.config.params.toString()
        } else {
          info.urlQueryString = stringifyQuery(i.config.params)
        }
      }
      info.request = {
        headers: cloneDeep(i.config?.headers),
        status: i.request?.status,
        data: cloneDeep(i.config?.data),
      }
      info.response = {
        headers: cloneDeep(i.headers),
        status: i.status,
        data: cloneDeep(i.data),
      }
    }

    // console.log('🚀 ~ sendInfo ~ info:', info)
    return axios.post('/info', info)
  }

  return { sendError, sendInfo }
}

const plugin: Plugin = (ctx, inject) => {
  inject('logService', createPlugin(ctx))
}

export type LogService = ReturnType<typeof createPlugin>
export default plugin
