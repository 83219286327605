import { Service } from 'axios-middleware'
import type { AxiosInstance } from 'axios'
import {
  URL_CLIENT_TOKEN,
  URL_ANONYMOUS_TOKEN,
  URL_CUSTOMERS_TOKEN,
  URL_REVOKE_TOKEN,
  URL_REFRESH_TOKEN,
} from '../util/constants'

interface ClientCredentials {
  clientId: string
  clientSecret: string
}

interface ClientOptions {
  axios: any
  credentials?: ClientCredentials
}

export class OAuthClient {
  axios: any
  credentials?: ClientCredentials

  static buildClient(options: ClientOptions) {
    return new this(options)
  }

  constructor(options: ClientOptions) {
    this.axios = options.axios
    this.credentials = options.credentials // credentials are defined only in static page generation
    // console.log(this.credentials)
  }

  fetchClientCredentialsToken() {
    console.log('::: fetchClientCredentialsToken')
    const options: any = {}
    if (this.credentials) {
      options.headers = {
        Authorization: this.getBasicAuthorizationHeader(),
      }
      options.params = { grant_type: 'client_credentials' }
    }
    return this.axios.post(URL_CLIENT_TOKEN, undefined, options)
  }

  fetchAnonymousToken() {
    console.log('::: fetchAnonymousToken')
    const options: any = {}
    if (this.credentials) {
      options.headers = {
        Authorization: this.getBasicAuthorizationHeader(),
      }
      options.params = { grant_type: 'client_credentials' }
    }
    return this.axios.post(URL_ANONYMOUS_TOKEN, undefined, options)
  }

  fetchCustomerToken(username: string, password: string) {
    console.log('::: fetchCustomerToken')
    const options: any = {}
    let data: any
    if (this.credentials) {
      options.headers = {
        Authorization: this.getBasicAuthorizationHeader(),
      }
      options.params = { grant_type: 'password', username, password }
    } else {
      data = { email: username, password }
    }

    return this.axios.post(URL_CUSTOMERS_TOKEN, data, options)
  }

  fetchRefreshToken(refreshToken?: string) {
    console.log('::: refreshToken')
    const options: any = {}
    let url = URL_REFRESH_TOKEN
    if (this.credentials) {
      url = URL_CLIENT_TOKEN
      options.headers = {
        Authorization: this.getBasicAuthorizationHeader(),
      }
      options.params = { grant_type: 'refresh_token', refresh_token: refreshToken }
    }
    return this.axios.post(url, undefined, options)
  }

  revokeToken(accessToken?: string) {
    console.log('::: revokeToken')
    const options: any = {}
    if (this.credentials) {
      options.headers = {
        Authorization: this.getBasicAuthorizationHeader(),
      }
      options.params = { token: accessToken }
    }
    return this.axios.post(URL_REVOKE_TOKEN, undefined, options)
  }

  private getBasicAuthorizationHeader() {
    return `Basic ${Buffer.from(`${this.credentials?.clientId}:${this.credentials?.clientSecret}`).toString('base64')}`
  }
}
