/* eslint-disable no-redeclare */

import { Logger } from 'pino'
import { performance } from './performance'

export function createTimeIt(logger: Logger, messagePrefix = '') {
  // Calling `$logger.child({...}, {msgPrefix: 'foo'})` doesn't want to
  // set message prefix even though it should based on a documentation.
  // (https://github.com/pinojs/pino/blob/master/docs/api.md#optionsmsgprefix-string)
  // For this reason the `messagePrefix` argument was added.

  function timeIt<TFunc extends (...args: any[]) => any>(prefix: string, func: TFunc): ReturnType<TFunc>
  function timeIt<TFunc extends (...args: any[]) => any>(func: TFunc): ReturnType<TFunc>
  function timeIt<TFunc extends (...args: any[]) => any>(prefixOrFunc: string | TFunc, func?: TFunc) {
    const prefix = typeof prefixOrFunc === 'string' ? `${prefixOrFunc}: ` : ''
    const realFunc = typeof prefixOrFunc === 'string' ? func! : prefixOrFunc

    const start = performance.now()
    const res = realFunc()

    if (res instanceof Promise) {
      res.finally(() => {
        const totalTime = (performance.now() - start).toFixed(2)
        logger.debug({ perf: { totalTime: `${totalTime}ms` } }, '%s%s%dms', messagePrefix, prefix, totalTime)
      })
    } else {
      const totalTime = (performance.now() - start).toFixed(2)
      logger.debug({ perf: { totalTime: `${totalTime}ms` } }, '%s%s%dms', messagePrefix, prefix, totalTime)
    }

    return res
  }

  return timeIt
}
