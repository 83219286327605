






































import { defineComponent, ref, useContext, onMounted, shallowSsrRef } from '@nuxtjs/composition-api'
import { constants } from '@cstweb/commercetools-client'

import countries from '~/static/countries.json'
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'

import { useVueModal } from '@/composables/ui/useVueModal'

import { useConfigStore } from '~/store/config'

export default defineComponent({
  components: { IconFa6SolidXmark },
  setup() {
    const ctx = useContext()
    const configStore = useConfigStore()

    const regionalSiteAvailable = shallowSsrRef(false, 'regionalSiteAvailable')
    const shouldAutoRedirect: boolean | undefined = ctx.$cookies.get('proxy_prompt') // When undefined show modal
    const saveSelection = ref(shouldAutoRedirect ?? false)
    const modal = useVueModal()

    // ..
    const redirectToRegionalSite = () => {
      const countryCode: string = ctx.$cookies.get(constants.COOKIES.HYBRID_COUNTRY_CODE) ?? 'US'
      const languageCode: string = ctx.$cookies.get(constants.COOKIES.HYBRID_LANGUAGE_CODE) ?? 'en'

      const isJapanLanguage = languageCode.toLocaleLowerCase() === 'jp'

      try {
        const host = location.origin
        const newUrl = new URL(location.href)

        if ((countryCode === 'JP' && isJapanLanguage) || countryCode === 'CN') {
          let newDomain = countryCode === 'JP' ? ctx.$config.cst.domainJapan ?? host : host
          newDomain = countryCode === 'CN' ? ctx.$config.cst.domainChina ?? host : newDomain ?? host

          // Language as parameter will trigger changing of different domain cookies
          newUrl.searchParams.append('language', languageCode)
          newUrl.host = new URL(newDomain).host
        }

        if (location.href !== newUrl.toString()) {
          location.href = newUrl.toString()
        }
      } catch (error) {
        console.error(error)
      }
    }

    // ..
    const handleModalClosure = (shouldRedirect: boolean) => {
      if (saveSelection.value) {
        ctx.$cookies.set('proxy_prompt', shouldRedirect)
      }

      if (shouldRedirect) {
        redirectToRegionalSite()
      }

      modal.hide('redirect-visitor')
    }

    // .. regionalSiteAvailable set in @middleware/with-data.ts
    if (process.server) {
      regionalSiteAvailable.value = configStore.regionalSiteAvailable
    }

    onMounted(() => {
      // .. Just show modal
      if (regionalSiteAvailable.value && shouldAutoRedirect === undefined) {
        modal.show('redirect-visitor')
      }

      // .. Redirect to available regional site
      if (regionalSiteAvailable.value && shouldAutoRedirect) {
        redirectToRegionalSite()
      }
    })

    return {
      saveSelection,
      countries,
      handleModalClosure,
      redirectToRegionalSite,
      shouldAutoRedirect,
    }
  },
})
