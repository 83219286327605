import { NuxtConfig } from '@nuxt/types'

// GraphQL Queries
import fetchGlobalHeaderAndFooter from './queries/fetchGlobalHeaderAndFooter.gql'
import fetchDynadmin from './queries/fetchDynadmin.gql'
import fetchPDPTemplateQuery from './queries/fetchPDPTemplate.gql'
import fetchPDPPageQuery from './queries/fetchPDPPage.gql'
import fetchPageByDevKey from './queries/fetchPageByDevKey.gql'
import fetchContentFragment from './queries/fetchContentFragment.gql'
import fetchContentTarget from './queries/fetchContentTarget.gql'
import fetchPDPTemplateAndPDPPage from './queries/fetchPDPTemplateAndPDPPage.gql'
import fetchNavigationByDevKey from './queries/navigation/fetchNavigationByDevKey.gql'
import fetchManyByDevKeysNavigation from './queries/navigation/fetchManyByDevKeysNavigation.gql'
import fetchRegionalSettings from './queries/fetchRegionalSettings.gql'
import fetchCmsContent from './queries/fetchCmsContent.gql'
import fetchManyContentTargets from './queries/fetchManyContentTargets.gql'
import fetchManyGlobalSettings from './queries/fetchManyGlobalSettings.gql'

// GraphQL Queries Types
import type {
  FetchNavigationByDevKeyQueryVariables,
  FetchNavigationByDevKeyQuery,
  FetchManyByDevKeysNavigationQueryVariables,
  FetchManyByDevKeysNavigationQuery,
  FetchDynadminQueryVariables,
  FetchDynadminQuery,
  FetchPageByDevKeyQueryVariables,
  FetchPageByDevKeyQuery,
  FetchPdpTemplateByProductIdAndTypeQueryVariables,
  FetchPdpTemplateByProductIdAndTypeQuery,
  FetchPdpPageByProductIdQueryVariables,
  FetchPdpPageByProductIdQuery,
  FetchContentFragmentByUidQueryVariables,
  FetchContentFragmentByUidQuery,
  FetchContentTargetByDevKeyQueryVariables,
  FetchContentTargetByDevKeyQuery,
  FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery,
  FetchPdpTemplateAndPdpPageByProductIdAndTypeQueryVariables,
  FetchRegionalSettingsQueryVariables,
  FetchRegionalSettingsQuery,
  FetchGlobalHeaderAndFooterByUidQueryVariables,
  FetchGlobalHeaderAndFooterByUidQuery,
  FetchCmsContentQuery,
  FetchCmsContentQueryVariables,
  FetchManyContentTargetByDevKeysQueryVariables,
  FetchManyContentTargetByDevKeysQuery,
  FetchManyGlobalSettingsQuery,
  FetchManyGlobalSettingsQueryVariables,
} from './types/generated/types'

const queries = {
  fetchGlobalHeaderAndFooter,
  fetchDynadmin,
  fetchPDPTemplateQuery,
  fetchPDPPageQuery,
  fetchContentFragment,
  fetchContentTarget,
  fetchNavigationByDevKey,
  fetchManyByDevKeysNavigation,
  fetchPageByDevKey,
  fetchPDPTemplateAndPDPPage,
  fetchRegionalSettings,
  fetchCmsContent,
  fetchManyContentTargets,
  fetchManyGlobalSettings,
}

function buildCMSPlugin(config: NuxtConfig) {
  const client = config.app.apolloProvider?.clients.cms

  const buildQuery =
    <V = unknown, Q = unknown>(query: any) =>
    (variables?: V) =>
      client.query({
        query,
        variables,
      }) as Promise<{ data: Q; errors?: Error[] }>

  const api = {
    fetchGlobalHeaderAndFooter: buildQuery<
      FetchGlobalHeaderAndFooterByUidQueryVariables,
      FetchGlobalHeaderAndFooterByUidQuery
    >(queries.fetchGlobalHeaderAndFooter),

    fetchDynadmin: buildQuery<FetchDynadminQueryVariables, FetchDynadminQuery>(queries.fetchDynadmin),

    fetchPDPTemplate: buildQuery<
      FetchPdpTemplateByProductIdAndTypeQueryVariables,
      FetchPdpTemplateByProductIdAndTypeQuery
    >(queries.fetchPDPTemplateQuery),

    fetchPDPPageQuery: buildQuery<FetchPdpPageByProductIdQueryVariables, FetchPdpPageByProductIdQuery>(
      queries.fetchPDPPageQuery
    ),

    fetchPageByDevKey: buildQuery<FetchPageByDevKeyQueryVariables, FetchPageByDevKeyQuery>(queries.fetchPageByDevKey),

    fetchRegionalSettings: buildQuery<FetchRegionalSettingsQueryVariables, FetchRegionalSettingsQuery>(
      queries.fetchRegionalSettings
    ),

    fetchContentFragment: buildQuery<FetchContentFragmentByUidQueryVariables, FetchContentFragmentByUidQuery>(
      queries.fetchContentFragment
    ),

    fetchContentTarget: buildQuery<FetchContentTargetByDevKeyQueryVariables, FetchContentTargetByDevKeyQuery>(
      queries.fetchContentTarget
    ),

    fetchNavigationByDEVKey: buildQuery<FetchNavigationByDevKeyQueryVariables, FetchNavigationByDevKeyQuery>(
      queries.fetchNavigationByDevKey
    ),

    fetchManyByDevKeysNavigation: buildQuery<
      FetchManyByDevKeysNavigationQueryVariables,
      FetchManyByDevKeysNavigationQuery
    >(queries.fetchManyByDevKeysNavigation),

    fetchPDPTemplateAndPDPPage: buildQuery<
      FetchPdpTemplateAndPdpPageByProductIdAndTypeQueryVariables,
      FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery
    >(queries.fetchPDPTemplateAndPDPPage),

    fetchCmsContent: buildQuery<FetchCmsContentQueryVariables, FetchCmsContentQuery>(queries.fetchCmsContent),

    fetchManyContentTargets: buildQuery<
      FetchManyContentTargetByDevKeysQueryVariables,
      FetchManyContentTargetByDevKeysQuery
    >(queries.fetchManyContentTargets),

    fetchManyGlobalSettings: buildQuery<FetchManyGlobalSettingsQueryVariables, FetchManyGlobalSettingsQuery>(
      queries.fetchManyGlobalSettings
    ),
  }

  return {
    do: () => {
      config.$logger.info('CMS Plugin Execute')
    },
    client,
    queries,
    api,
  }
}

export type CMSPlugin = ReturnType<typeof buildCMSPlugin>

export default function (config: any, inject: any) {
  inject('cms', buildCMSPlugin(config))
}
