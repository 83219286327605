import { defineStore } from 'pinia'

import countryToCurrency from 'country-to-currency'
import { useProfileStore } from './profile'
import { useCheckoutStore } from './checkout'
import { useConfigStore } from './config'

import { useFeatureStore } from './featureFlags'
import { UserStoreInterface } from '@/types/user/userTypes'
import { useI18n } from '~/composables/i18n'

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserStoreInterface => ({
    selectedCountry: 'US',
    selectedLanguage: 'en',
    selectedCurrency: 'USD',
  }),
  getters: {
    selectedLocale(): string {
      return useI18n(this.$nuxt).localeProperties.value.iso!
    },
  },
  actions: {
    init(currentCountryCode: string, languageCode: string) {
      const cc = currentCountryCode !== 'USA' ? currentCountryCode : 'US' // FIXME: remove 'USA' after ATG Sunset

      this.$patch({
        selectedCountry: cc,
        selectedCurrency: countryToCurrency[cc as keyof typeof countryToCurrency],
        selectedLanguage: languageCode,
      })
    },
    patchCountry(currentCountryCode: string) {
      const cc = currentCountryCode !== 'USA' ? currentCountryCode : 'US' // FIXME: remove 'USA' after ATG Sunset
      this.$patch({
        selectedCountry: cc,
      })
    },
    async logout() {
      await this.$nuxt.$ct.auth.logout()

      // Reset the stores, but save one prop
      useProfileStore().$reset()
      useCheckoutStore().$reset()
      await useFeatureStore().fetchAll()
    },
    async login({ username, password }: any) {
      const { data } = await this.$nuxt.$ct.auth.login({
        username,
        password,
      })

      data.cart ? useCheckoutStore().setBasket(data.cart, false) : useCheckoutStore().$reset()
      data.customer && this.init(data.customer.custom.fields.registrationOrigin, 'en') // en since jp/cn does not have login feature
      data.customer &&
        useConfigStore().set({ countryCode: data.customer.custom.fields.registrationOrigin, languageCode: 'en' }) // en since jp/cn does not have login feature
      data.customer && useProfileStore().setProfile(data.customer, false)

      return data
    },
    async register({
      firstName,
      lastName,
      email,
      password,
      organization,
      country,
      vatId,
      recaptchaToken,
      isNewsletterOptin,
    }: any) {
      const { data } = await this.$nuxt.$ct.auth.signup({
        firstName,
        lastName,
        email,
        password,
        companyName: organization,
        country,
        vatId: vatId === '' ? undefined : vatId,
        recaptchaToken,
        custom: {
          fields: {
            isNewsletterOptin,
            privacyAgreedToDateTime: new Date().toISOString(),
          },
        },
      })

      data.cart ? useCheckoutStore().setBasket(data.cart, false) : useCheckoutStore().$reset()
      data.customer && this.init(data.customer.custom.fields.registrationOrigin, 'en') // en since jp/cn does not have login feature
      data.customer &&
        useConfigStore().set({ countryCode: data.customer.custom.fields.registrationOrigin, languageCode: 'en' }) // en since jp/cn does not have login feature
      data.customer && useProfileStore().setProfile(data.customer, false)

      return data
    },
    async requestPasswordReset({ email }: any) {
      await this.$nuxt.$ct.auth.requestPasswordReset({
        email,
      })
    },
    async resetPassword({ token, password }: any) {
      await this.$nuxt.$ct.auth.resetPassword({
        token,
        password,
      })

      useCheckoutStore().$reset()
      useProfileStore().$reset()
    },
    async verifyEmailToken({ token }: any) {
      await this.$nuxt.$ct.auth.verifyEmailToken({
        token,
      })
    },
    async resendVerificationEmail({ email }: any) {
      await this.$nuxt.$ct.auth.resendVerificationEmail({
        email,
      })
    },

    async changePassword({ oldPassword, newPassword }: any) {
      await this.$nuxt.$ct.auth.changePassword({
        oldPassword,
        newPassword,
      })

      useCheckoutStore().$reset()
      useProfileStore().$reset()
    },
  },
})
