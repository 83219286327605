







import { defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { $config } = useContext()
    const target = $config.cst.isStatic ? 'STATIC' : 'SSR'
    const timestamp = new Date().toISOString()

    const debugInfo = {
      renderTarget: target,
      renderTimestamp: timestamp,
    }

    return { debugInfo }
  },
})
