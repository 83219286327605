import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { EvaluatedFeatures, FeatureDefinitions, FEATURES } from '~/features/features'
import { FetchFeatureResult } from '~/types/featureFlags/featureFlagsPlugin'

const DEFAULT_STALE_TIME = 60 * 5 // 5 minutes

type EvaluatedFeaturesWithTimestamp = {
  [K in keyof EvaluatedFeatures]: EvaluatedFeatures[K] & { fetchedAt: number; value: string | boolean | null }
}
export const useFeatureStore = defineStore({
  id: 'features',
  state: () => ({
    featureFlags: null as EvaluatedFeaturesWithTimestamp | null,
  }),

  actions: {
    async fetchAll() {
      try {
        const evaluatedFeatures = await this._fetchAllFlags()
        return this._patch(evaluatedFeatures)
      } catch (error) {
        console.error(error)

        // .. TODO: Remove after we fix batchEvaluate Quote limit
        return this._fakePatch()
      }
    },

    _fetchAllFlags() {
      return this.$nuxt.$featureFlags.fetchMany(
        Object.values(FEATURES).map((f) => ({ name: f.name, evaluateBasedOn: f.evaluateBasedOn }))
      )
    },

    _patch(data: FetchFeatureResult[]) {
      const newFeatureFlags = {} as EvaluatedFeaturesWithTimestamp
      for (const feature of data) {
        const featureKey = Object.entries(FEATURES).find(
          ([_key, value]) => value.name === feature.name
        )![0] as keyof FeatureDefinitions
        newFeatureFlags[featureKey] = {
          ...feature,
          // @ts-ignore
          fetchedAt: Date.now(),
        }
      }

      this.featureFlags = newFeatureFlags

      return this.featureFlags
    },

    // .. Will patch store with fake values with to prevent errors
    _fakePatch() {
      const newFeatureFlags = {} as EvaluatedFeaturesWithTimestamp
      const selectedCountry = useUserStore().selectedCountry

      Object.entries(FEATURES).forEach(([_key, featureContent]) => {
        let featureValue: boolean | string = false

        // .. Remove after WEBP-9569
        // .. Enable showing quotes for customers if evidently is off
        if (_key === 'showQuotes') {
          const quoteCountries = ['AT', 'DE', 'GB', 'JP', 'US']
          featureValue = quoteCountries.includes(selectedCountry)
        }

        // .. Enable showing quotes for customers if evidently is off
        if (_key === 'homepageVariation') {
          const oldVariationCountries = ['CN', 'JP']
          featureValue = oldVariationCountries.includes(selectedCountry) ? 'a' : 'b'
        }

        newFeatureFlags[_key as keyof FeatureDefinitions] = {
          ...featureContent,
          // @ts-ignore
          value: featureValue,
          // @ts-ignore
          // .. Set time for -4 minutes, so we can retry in 1 minute
          fetchedAt: new Date(new Date().getTime() - 4 * 60000).valueOf(),
        }
      })

      this.featureFlags = newFeatureFlags

      return this.featureFlags
    },

    async fetch<TFeatureKey extends keyof FeatureDefinitions>(
      featureKey: TFeatureKey
    ): Promise<EvaluatedFeatures[TFeatureKey]['value']> {
      const feature = FEATURES[featureKey]
      const featureName = feature.name
      const evaluationOption = feature.evaluateBasedOn

      const revalidatedFeature = await this.$nuxt.$featureFlags.fetch(featureName, evaluationOption)

      if (!this.featureFlags) {
        console.warn('You are trying to write to featureFlags before they are fetched.')
        this.featureFlags = {} as EvaluatedFeaturesWithTimestamp
      }

      // @ts-ignore
      this.featureFlags[featureKey] = {
        ...revalidatedFeature,
        fetchedAt: Date.now(),
      }

      return this.featureFlags[featureKey].value as EvaluatedFeatures[TFeatureKey]['value']
    },

    /**
     * @param staleTime Stale time in seconds
     */
    async fetchIfStale<TFeatureKey extends keyof FeatureDefinitions>(
      featureKey: TFeatureKey,
      staleTime: number = DEFAULT_STALE_TIME
    ): Promise<EvaluatedFeatures[TFeatureKey]['value']> {
      if (!this.featureFlags?.[featureKey] || Date.now() - this.featureFlags[featureKey].fetchedAt > staleTime * 1000) {
        await this.fetch(featureKey)
      }

      return this.featureFlags![featureKey].value as EvaluatedFeatures[TFeatureKey]['value']
    },
  },
})
