import { CustomAxiosResponse, CustomAxiosError } from '@cstweb/common'
import { Client } from '../../client'
import { hasRefreshToken, hasCustomer, hasPunchoutCustomer } from '../../util'

import {
  AUTH_STORE_FIELD,
  URL_CLIENT_TOKEN,
  URL_ANONYMOUS_TOKEN,
  URL_CUSTOMERS_TOKEN,
  URL_REFRESH_TOKEN,
  URL_REVOKE_TOKEN,
  TOKEN_FLOW,
} from '../../util/constants'

export default (client: Client) => {
  const authCookieStore = client.cookies.auth
  const customerCookieStore = client.cookies.customer
  const hooks = client.hooks

  return {
    onResponse(response: CustomAxiosResponse) {
      const { config } = response
      if (
        config.url &&
        [URL_CLIENT_TOKEN, URL_ANONYMOUS_TOKEN, URL_CUSTOMERS_TOKEN, URL_REFRESH_TOKEN].includes(config.url)
      ) {
        const tokenData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data

        const isRefreshTokenFlow = hasRefreshToken(authCookieStore)

        // set expiresIn 30 minutes before real expiration time
        // const expiresIn = Date.now() + (tokenData.expires_in - 30 * 60) * 1000

        const expiresIn = Date.now() + tokenData.expires_in * 1000

        console.debug('CTC: SETTING AUTH COOKIE')

        authCookieStore.set(AUTH_STORE_FIELD.ACCESS_TOKEN, tokenData.access_token)
        authCookieStore.set(AUTH_STORE_FIELD.EXPIRES_IN, expiresIn)
        authCookieStore.set(AUTH_STORE_FIELD.EXPIRES_AT, new Date(expiresIn))

        if (tokenData.refresh_token) {
          authCookieStore.set(AUTH_STORE_FIELD.REFRESH_TOKEN, tokenData.refresh_token)
        } else {
          !isRefreshTokenFlow && authCookieStore.set(AUTH_STORE_FIELD.REFRESH_TOKEN, undefined)
        }

        if (config.url) {
          let flow

          if (config.url.startsWith(URL_CLIENT_TOKEN)) {
            flow = TOKEN_FLOW.CLIENT
          }
          if (config.url.startsWith(URL_ANONYMOUS_TOKEN)) {
            flow = TOKEN_FLOW.ANONYMOUS
          }
          if (config.url.startsWith(URL_CUSTOMERS_TOKEN)) {
            flow = TOKEN_FLOW.CUSTOMER
          }

          !isRefreshTokenFlow && authCookieStore.set(AUTH_STORE_FIELD.FLOW, flow)
        }

        authCookieStore.persist()
      }

      if (config.url && [URL_REVOKE_TOKEN].includes(config.url)) {
        client.clearCookies()
      }

      return response
    },
    onResponseError(error: CustomAxiosError): Promise<any> {
      console.debug(`CTC: Cleaning Cookies...(all)[${error.response?.status}]`)

      const isLoggedIn = hasCustomer(customerCookieStore)
      const isPunchout = hasPunchoutCustomer(customerCookieStore)

      if (error.response?.status) {
        client.clearCookies()
        hooks.onSessionExpired && hooks.onSessionExpired({ isLoggedIn, isPunchout })
      } else {
        hooks.onNetworkError && hooks.onNetworkError(error)
      }

      throw error
    },
  }
}
