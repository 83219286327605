



























import { defineComponent, useRoute, useMeta, watch, computed } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

import Header from '../components/global/Header.vue'
import RenderDebug from '~/components/global/RenderDebug.vue'
import RedirectVisitorModalVue from '~/components/feature/country-selector/RedirectVisitorModal.vue'

import Footer from '@/components/global/footer/Footer.vue'
import { useSidebarStore, useUserStore } from '@/store'
import BackToTopButton from '~/components/global/back-to-top/BackToTopButton.vue'

import LogMiddleware from '@/middleware/log'
import WithDataMiddleware from '@/middleware/with-data'

export default defineComponent({
  components: {
    Header,
    RedirectVisitorModalVue,
    Footer,
    BackToTopButton,
    RenderDebug,
  },
  middleware: [LogMiddleware, WithDataMiddleware],
  setup() {
    const route = useRoute()
    const { sidebar } = useSidebarStore()
    const { htmlAttrs } = useMeta()
    const { selectedLanguage } = storeToRefs(useUserStore())

    watch(selectedLanguage, () => {
      // update the 'lang' attribute on the 'html' element whenever the language is changed
      htmlAttrs.value = { ...htmlAttrs.value, lang: selectedLanguage.value }
    })

    // hide back to top button for PDPs only
    const displayBackToTopButton = computed(
      () => route.value?.name !== 'products-id' && route.value?.name !== 'custom-products-id'
    )
    return {
      route,
      sidebar,
      displayBackToTopButton,
    }
  },
  head: {},
})
