import { useContext } from '@nuxtjs/composition-api'
import { findCookieDomain, mergeCookieSettings } from '@cstweb/common'
import { storeToRefs } from 'pinia'
import { constants } from '@cstweb/commercetools-client'
import { useUserStore } from '@/store/user'
import { useCheckoutStore } from '@/store/checkout'
import { useI18n } from '~/composables/i18n'
import { useUserStatus } from '@/composables/user/useUser'
// import useCountryAnalytics from '@/composables/analytics/useCountryAnalytics'
import { useCMSDynadminStore } from '@/store/cms'

export function useCountrySelector() {
  const { $cookies, $config } = useContext()
  const i18n = useI18n()
  // const { trackCountrySwitch } = useCountryAnalytics()
  const userStore = useUserStore()
  const checkoutStore = useCheckoutStore()
  const { isLoggedIn, hasCart } = useUserStatus()
  const { b2dAllowedAddToBasket } = storeToRefs(useCMSDynadminStore())

  const changeCountry = async ({ oldCountry, newCountry }: { oldCountry: string; newCountry: string }) => {
    // TODO, do we want this in GA4?
    // trackCountrySwitch(oldCountry, newCountry)

    const onProxy = oldCountry === 'JP' || oldCountry === 'CN'
    const toProxy = newCountry === 'JP' || newCountry === 'CN'

    const url = new URL(location.href)
    const cookieDomain = findCookieDomain(url.origin)
    const isNuxtHost = url.host.includes('nuxt')
    const isPRHost = url.host.includes('pr-cstweb-frontend')

    // Handle domain change
    if (isNuxtHost || isPRHost) {
      // Special case - Do nothing
    } else if (toProxy) {
      // Change host to JP/CN domain
      const newDomain = (newCountry === 'JP' ? $config.cst.domainJapan : $config.cst.domainChina) ?? url.origin
      url.host = new URL(newDomain).host
    } else if (onProxy && !toProxy) {
      // Change host to .com domain & add `country` query param
      url.host = new URL($config.cst.domainDefault ?? url.origin).host
      url.searchParams.append('country', newCountry)
    }

    if (location.href !== url.toString()) {
      // Domain changed, unable to set cookie for different domain, cookie is set on ssr page reload
      location.href = url.toString()
      return
    }

    if (newCountry !== oldCountry) {
      // Domain is the same and country was changed
      $cookies.set(
        constants.COOKIES.HYBRID_COUNTRY_CODE,
        newCountry !== 'US' ? newCountry : 'USA', // FIXME: remove 'USA' after ATG Sunset
        mergeCookieSettings({ domain: cookieDomain })
      )
      i18n.setLocale(newCountry.toLowerCase())

      const isB2DCountryChange =
        b2dAllowedAddToBasket.value.includes(newCountry.toLowerCase()) &&
        b2dAllowedAddToBasket.value.includes(oldCountry.toLowerCase())

      if (isLoggedIn.value) {
        await userStore.logout()
      } else if (hasCart.value && !isB2DCountryChange) {
        await checkoutStore.removeBasket()
      }
    }

    // NOTE: even though country code may not changed, still reload the page. Automation tests expect it to happen.
    location.reload()
  }

  const changeLanguage = ({ language: newLanguage }: { language: string }) => {
    const currentCountry = userStore.selectedCountry
    const toProxy =
      (currentCountry === 'JP' && newLanguage === 'jp') || (currentCountry === 'CN' && newLanguage === 'cn')

    const url = new URL(location.href)
    const cookieDomain = findCookieDomain(url.origin)
    const isNuxtHost = url.host.includes('nuxt')
    const isPRHost = url.host.includes('pr-cstweb-frontend')

    if (isNuxtHost || isPRHost) {
      // Special case - Do nothing
    } else if (toProxy) {
      // Change host to JP/CN domain
      const newDomain = (currentCountry === 'JP' ? $config.cst.domainJapan : $config.cst.domainChina) ?? url.origin
      url.host = new URL(newDomain).host
      // Trigger changing of cookies if is it on ATG site
      url.searchParams.append('language', newLanguage)
    } else {
      // All other languages are handled by .com domain
      url.host = new URL($config.cst.domainDefault ?? url.origin).host
      url.searchParams.append('country', currentCountry)
      url.searchParams.append('language', newLanguage)
    }

    if (url.toString() !== location.href) {
      // Domain changed, unable to set cookie for different domain, cookie is set on ssr page reload
      location.href = url.toString()
      return
    }

    $cookies.set(constants.COOKIES.HYBRID_LANGUAGE_CODE, newLanguage, mergeCookieSettings({ domain: cookieDomain }))
    i18n.setLocale(newLanguage)

    location.reload()
  }

  return {
    changeCountry,
    changeLanguage,
  }
}
