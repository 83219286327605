import { ref, useContext } from '@nuxtjs/composition-api'

// Axios
import axios, { AxiosResponse } from 'axios'

// loDash
import { storeToRefs } from 'pinia'
import cloneDeep from 'lodash/fp/cloneDeep'
import isEmpty from 'lodash/fp/isEmpty'

// Composables
import useAppSearch from './useAppSearch'
import useFacets from '@/composables/search/useFacets'

// Common
import { firstCharUpperCase } from '@/common/utils/characters'
import { useSearchStore } from '~/store'
import { getRelativeUrl } from '@/common/utils/urls'
import { getSearchHistoryObject, filterSearchHistory } from '~/common/utils/searchHistory'

// Types
import {
  SuggestionAreaInterface,
  SearchOptionsInterface,
  FacetsObjectInterface,
  StaticFacetsInterface,
  SuggestsObjectInterface,
} from '@/types/search/searchTypes'
import { ElasticFieldsInterface } from '@/types/search/elasticTypes'

import {
  SEARCH_TYPES,
  STOP_WORDS,
  SEARCH_RESULT_FIELDS_PRODUCT,
  SEARCH_RESULT_FIELDS_RESOURCES,
} from '@/common/static/search'

import { showAllFacetsArray, facetStatusArray, noneFilterArray } from '@/common/static/facets'

/*
  Composable to support the search functionality
  This is the main search composable  
*/
export default () => {
  // Consts & refs
  const loadingSuggests = ref(false)
  const searchStore = useSearchStore()
  const { storedSearchInput, storedTypeaheadSearchInput, searchErrors, infoPanelsOpen, infoPanelOpenIDs } =
    storeToRefs(searchStore)
  const { getSelectedFacetCount } = useFacets()
  const {
    getElasticSearchEndpoint,
    getAxiosConfig,
    getElasticMultiSearchEndpoint,
    getElasticQuerySuggestionsEndpoint,
    getElasticBase,
  } = useAppSearch()

  const { $logService } = useContext()

  // --------------------------------
  // Settings
  // --------------------------------
  /*
      Set search settings to a default mode

      RETURNS: void    
    */
  const setDefaultSearchSettings = (newSearch: boolean = false) => {
    // Current page to 1
    searchStore.setCurrentPage(1)

    // tab to products
    searchStore.setSelectedTab(SEARCH_TYPES.PRODUCT)

    // delete all checked facets
    searchStore.deleteCheckedFacets()

    // if a new search, delete the clickedMultiSelect facets too
    if (newSearch) {
      searchStore.deleteClickedMultipleFacets()
    }

    // Hide suggests
    searchStore.hideSuggests()

    // Reset sort by
    searchStore.setSortField('Relevance')

    // Make sure, we show 10 history items in the right layout
    searchStore.setShowHistoryOnly(true)

    // Close all info panels
    searchStore.closeAllInfoPanels()
  }

  // --------------------------------
  // Search
  // --------------------------------

  /*
    Manually insert stop words by removing them from the search input during a search

    RETURNS: string
  */
  const formattedSearchInput = () => {
    // return searchStore.searchInput
    //   .split(' ')
    //   .filter((word) => !STOP_WORDS.includes(word))
    //   .join(' ')
    //   .trim()
    return searchStore.searchInput
      .split(' ')
      .filter((word) => !STOP_WORDS.includes(word))
      .join(' ')
      .trim()
  }

  /*
    Performs a regular search
    stores data but does not return anything

    RETURNS: Promise
  */
  const performSearch = async (queryoptions: SearchOptionsInterface = {}): Promise<any> => {
    // start responsetime var
    const responseTimeStart = new Date()

    // clear possible errors
    clearSearchErrors()

    // change loader status
    searchStore.changeLoader(true)

    // change searching status
    searchStore.setShowSearching(true)

    // close any open application infoPanel
    infoPanelsOpen.value = false

    // set storedSearchInput
    storedSearchInput.value = searchStore.searchInput
    storedTypeaheadSearchInput.value = searchStore.searchInput

    // optional config object
    // Add headers only on serverside
    // add optional auth prop for server-side requests
    // Server side requests are sent to Elasticsearch, client-side requests are sent to the Elasticsearch middleware with a relative URL
    const axiosConfig = getAxiosConfig(getSearchQueryObject(queryoptions), getRequestUrl())

    // Try and catch
    // Upon failure, set an error
    try {
      // get data
      const { data } = await axios(axiosConfig)

      // If result is a string, we know, there is a URL to redirect to (done in searchinputfield.vue)
      // if there is a second query
      if (data.length > 1) {
        // and the total_results is 1
        if (data[1].meta.page.total_results === 1) {
          // return object with redirect = true and the fields
          return { directproductnumber: true, ...data[1].results[0] }
        }
      }

      // Set the response data. This is a regular search
      handleSearchResponse(data, responseTimeStart)
      return false
    } catch (err) {
      await handleAxiosError(err)
    }
  } // end fetch function

  /*
  Perform an alternative search to place a number in the alternate tab

  RETURNS: Void
  */
  const performAlternativeSearch = async () => {
    const alternativeTab = searchStore.getAlternativeTab()
    const axiosConfig = getAxiosConfig(getSearchQueryObject({ tab: alternativeTab }), getRequestUrl(alternativeTab))

    try {
      const { data } = await axios(axiosConfig)

      // change searching status
      searchStore.setShowSearching(false)

      // Store results
      setElasticResults(data, alternativeTab)

      // calculate tab counts and check for redirection
      searchStore.setTabCount({
        product: searchStore.results[SEARCH_TYPES.PRODUCT].meta?.page?.total_results,
        resources: searchStore.results[SEARCH_TYPES.RESOURCE].meta?.page?.total_results,
      })

      // if product is selected and is 0
      if (
        searchStore.tabs.selectedTab === SEARCH_TYPES.PRODUCT &&
        searchStore.tabs.tabCount.product === 0 &&
        searchStore.tabs.tabCount.resources !== 0
      ) {
        return SEARCH_TYPES.RESOURCE
      }

      // if resoruces is selected and is 0
      if (
        searchStore.tabs.selectedTab === SEARCH_TYPES.RESOURCE &&
        searchStore.tabs.tabCount.resources === 0 &&
        searchStore.tabs.tabCount.product !== 0
      ) {
        return SEARCH_TYPES.PRODUCT
      }

      return null
    } catch (err) {
      handleAxiosError(err)
      return null
    }
  }

  /*
    Check search response, set the results in the store and set the response time
    
    RETURNS: string
  */
  const handleSearchResponse = (data: AxiosResponse, responseTimeStart: Date) => {
    // set in store
    setElasticResults(data)

    // set response time
    const responseTimeEnd = new Date()
    const responseTime = (responseTimeEnd.getTime() - responseTimeStart.getTime()) / 1000
    searchStore.setResponseTime(responseTime)

    // set redirect to true for future searches
    searchStore.setTypeaheadRedirect(true)
  }

  /*
    Take the returned JSON
    Check if we have one or two objects in the array
    If it is one, set the results in the store and resolve

    If it is two, we have a match on a direct productnumber search
    Resolve the URL to redirect from the setup function and return

    In the setup function, we test if the resolve is a boolean or a string
    The latter is a redirect

    RETURN: Boolean || String
  */
  const setElasticResults = (json: any, tab: string = '') => {
    if (json !== undefined) {
      // change the loader to false
      searchStore.changeLoader(false)

      // otheriwse, change the results if the first query
      searchStore.setResults(json[0], tab)
    }
  }

  // --------------------------------
  // URLS
  // --------------------------------

  /*
    Method to define the request URL based on the selected tab

    RETURN: string
  */
  const getRequestUrl = (tab: string = '') => {
    const selectedTab = tab.length > 0 ? tab : searchStore.tabs.selectedTab
    return getElasticBase(selectedTab) + '/multi_search'
  }

  /*
      Method to transform some scraped URLs
      TODO, this can be deleted once we stop scraping resources
      
      RETURNS: string
    */
  const getSearchResultUrl = (fields: ElasticFieldsInterface) => {
    const url = fields?.url?.raw ?? ''
    const urlAsString = url || String(fields)

    // For the blog subdomain, return the full url
    if (url.includes('blog.cellsignal') || url.includes('careers.cellsignal')) {
      return url
    }
    return getRelativeUrl(urlAsString)
  }

  // --------------------------------
  // TYPEAHEAD
  // --------------------------------

  /*
    An improved function to get the typeahead stacks
    This function can send a request to different engines

    RETURNS: result data
  */
  const elasticAppSearchTypeahead = async (type: string) => {
    let url = ''
    const queryObject = {} as any

    if (type === SEARCH_TYPES.RESOURCE) {
      // change query
      queryObject.query = formattedSearchInput()
      queryObject.page = { size: 3 }
      queryObject.search_fields = { title: {} }
      // set url
      url = getElasticSearchEndpoint(SEARCH_TYPES.RESOURCE)
    }

    if (type === SEARCH_TYPES.PRODUCT) {
      queryObject.queries = [
        {
          query: formattedSearchInput(),
          page: { size: 3 },
          filters: {
            all: [{ status: facetStatusArray }],
            none: noneFilterArray,
          },
          search_fields: { namenotags: {}, productnumber: {} },
          ...(getRedirectProductNumber().length > 0 && {
            boosts: {
              productnumber: [
                {
                  type: 'value',
                  value: formattedSearchInput(),
                  operation: 'multiply',
                  factor: 2,
                },
              ],
            },
          }),
        },
        {
          query: formattedSearchInput(),
          page: { size: 0 },
          filters: {
            all: [{ status: facetStatusArray }],
            none: noneFilterArray,
          },
          facets: {
            categories: [
              {
                type: 'value',
                sort: { count: 'desc' },
                size: 5,
              },
            ],
          },
        },
      ]

      url = getElasticMultiSearchEndpoint(SEARCH_TYPES.PRODUCT)
    }

    if (type === SEARCH_TYPES.CATEGORIES) {
      queryObject.query = formattedSearchInput()
      queryObject.page = { size: 0 }
      queryObject.filters = {
        all: [{ status: facetStatusArray }],
        none: noneFilterArray,
      }
      queryObject.facets = {
        categories: [
          {
            type: 'value',
            sort: { count: 'desc' },
            size: 5,
          },
        ],
      }

      url = getElasticSearchEndpoint(SEARCH_TYPES.PRODUCT)
    }

    if (type === SEARCH_TYPES.SUGGESTIONS) {
      queryObject.query = formattedSearchInput()
      queryObject.size = 3
      queryObject.types = {
        documents: {
          fields: ['targetnames'],
        },
      }

      // only search in products
      // Maybe in the future, search in resources if the resources tab is selected
      url = getElasticQuerySuggestionsEndpoint(SEARCH_TYPES.PRODUCT)
    }

    // start axios config
    const axiosConfig = getAxiosConfig(queryObject, url)

    // Try and catch
    try {
      // get data
      // And check the response for errors
      return await axios(axiosConfig).then((axiosResponse: any): AxiosResponse => {
        // we have data, return it
        return axiosResponse.data
      })
    } catch (err) {
      handleAxiosError(err)
    }
  }

  /*
      Get all typeahead stacks with different API calls
      After it's done, set them in the store
  
      RETURNS: void
    */
  const typeaheadGetAll = async () => {
    // Start
    loadingSuggests.value = true
    const payload = {} as SuggestsObjectInterface

    // Wait for them to complete
    const [resources, products, suggestions] = await Promise.all([
      elasticAppSearchTypeahead(SEARCH_TYPES.RESOURCE),
      elasticAppSearchTypeahead(SEARCH_TYPES.PRODUCT),
      elasticAppSearchTypeahead(SEARCH_TYPES.SUGGESTIONS),
    ])

    payload.history = {
      name: SEARCH_TYPES.HISTORY,
      type: SEARCH_TYPES.HISTORY,
      data: filterSearchHistory(searchStore.searchInput),
    }

    // Add to the payload
    payload.product = {
      name: SEARCH_TYPES.PRODUCT,
      type: 'pdp',
      data: products,
    }

    payload.categories = {
      name: SEARCH_TYPES.CATEGORIES,
      type: 'facet',
      data: products,
    }

    payload.resources = {
      name: SEARCH_TYPES.RESOURCE,
      type: 'pdp',
      data: resources,
    }

    payload.suggestions = {
      name: SEARCH_TYPES.SUGGESTIONS,
      type: 'suggestion',
      data: suggestions,
    }

    // Set in store
    searchStore.setSuggests(payload)
    loadingSuggests.value = false
  }

  /*
      Returns 10 latests history results
  
      RETURNS: object
    */
  const getHistorySuggests = () => {
    // get locatStorage items
    const payload = {
      history: {
        name: SEARCH_TYPES.HISTORY,
        type: SEARCH_TYPES.HISTORY,
        data: getSearchHistoryObject({ cap: 10 }),
      },
    }
    return payload
  }

  /*
      Prettyfy the typeahead name
  
      RETURNS: string
    */
  const prettyTypeaheadName = (suggestionArea: SuggestionAreaInterface): string => {
    let name = firstCharUpperCase(suggestionArea.name)

    if (name === 'Target' || name === 'Targetnames') {
      name = 'Protein group'
    }

    return name
  }

  // --------------------------------
  // REQUESTS
  // --------------------------------

  /*
    Method to get the redirect productnumber from the search phrase
    check if the searchphrase is longer then 3 characters
    also check if the searchphrase has no more then two characters

    RETURNS: String
  */
  const getRedirectProductNumber = () => {
    let searchProductNumber = ''

    if (
      searchStore.searchInput.length > 3 &&
      searchStore.tabs.selectedTab === SEARCH_TYPES.PRODUCT &&
      searchStore.searchInput.replace(/[^a-zA-Z]+/g, '').length < 2
    ) {
      const checkProductnumber = searchStore.searchInput.match(/\d{4,5}/gm)
      if (checkProductnumber !== null && checkProductnumber.length > 0) {
        searchProductNumber = checkProductnumber[0]
      }
    }

    return searchProductNumber
  }

  /*
    Method to define the elastic query

    RETURNS: possibly multiple queries wrapped in an object to send to the multi_search API
  */
  const getSearchQueryObject = (queryoptions: SearchOptionsInterface = {}) => {
    // local vars
    const selectedTab = queryoptions.tab !== undefined ? queryoptions.tab : searchStore.tabs.selectedTab

    // pagination
    // if it's an alternative search, set the size to 0
    const page = {
      size: queryoptions.tab !== undefined ? 0 : searchStore.page.resultsPerPage,
      current: queryoptions.nr !== 0 && queryoptions.nr !== undefined ? queryoptions.nr : searchStore.page.currentPage,
    }

    // set options along with the search phrase
    const options: any = {
      query: formattedSearchInput(),
      page,
      result_fields:
        selectedTab === SEARCH_TYPES.PRODUCT ? SEARCH_RESULT_FIELDS_PRODUCT : SEARCH_RESULT_FIELDS_RESOURCES,
    }

    // Optional sort & facets & filter for products
    if (selectedTab === SEARCH_TYPES.PRODUCT) {
      // facets
      options.facets = createElasticFacetArray(queryoptions.facets)

      // sorting
      options.sort = [{ [searchStore.getSortField]: searchStore.getOrder }]

      // filters
      // duplicate with cloneDeep to prevent checked facets
      const filterArray = cloneDeep(searchStore.facets.checkedFacets)
      const filteredFilterArray = []

      // if we have 1 filter with one value, this is a link from typeahead. In this case, do not check for empty counts
      // Otherwise, exclude filters with 0
      if (filterArray.length === 1 && Object.values(filterArray[0]).length === 1) {
        const facetKey = Object.keys(filterArray[0])[0]
        const facetValue = Object.values(filterArray[0])[0]
        filteredFilterArray.push({
          [facetKey]: facetValue,
        })
      }
      // exclude filters with (0)
      else {
        filterArray.forEach((facetObj) => {
          const facetKey = Object.keys(facetObj)[0]
          const facetValues = facetObj[facetKey].filter((val: string) => {
            const count = getSelectedFacetCount(facetKey, val)
            if (count !== undefined) {
              return count > 0
            } else {
              return true
            }
          })

          if (facetValues.length > 0) {
            filteredFilterArray.push({
              [facetKey]: facetValues,
            })
          }
        })
      }

      // Always add status filter
      filteredFilterArray.push({ status: facetStatusArray })

      options.filters = {
        all: filteredFilterArray,
        none: noneFilterArray,
      }
    }

    // console.log('options ', options)

    // setup the multi_search uery
    const queries = {
      queries: [options],
    }

    // console.log('queries ', queries)

    // check if we need to add another query
    if (searchStore.typeahead.redirect && selectedTab === SEARCH_TYPES.PRODUCT) {
      // test if we have an SKU
      // if so, set the value searchProductNumber and send as a queryoption to elasticappsearch function
      const searchProductNumber = getRedirectProductNumber()

      // if we found an actual number, add another query for productnumbers only
      if (searchProductNumber !== '') {
        // search for productnumber only
        // Search for all statusses, also the discontinued ones
        const productnumberOptions = {
          query: searchProductNumber,
          search_fields: {
            productnumber: {},
          },
          precision: 11,
        }
        queries.queries.push(productnumberOptions)
      }
    }

    return queries
  }

  // --------------------------------
  // FACETS
  // --------------------------------

  /*
    Get all facet values to store them in the cache

    RETURNS: facet object
  */
  const getAllFacetValues = async () => {
    const queryObject = {
      query: '',
      page: {
        size: 0,
      },
      facets: createElasticFacetArray(),
      filters: {
        all: [{ status: facetStatusArray }],
        none: noneFilterArray,
      },
    }

    const axiosConfig = getAxiosConfig(queryObject, getElasticSearchEndpoint(SEARCH_TYPES.PRODUCT))

    try {
      const axiosResponse = await axios(axiosConfig)
      if (axiosResponse.data !== undefined) {
        searchStore.facets.cachedFacets = axiosResponse.data.facets
      }
    } catch (err) {
      handleAxiosError(err)
    }
  }

  /*
        Store all requested facets
      */
  const storeCachedFacets = async () => {
    if (isEmpty(searchStore.facets.cachedFacets)) {
      await getAllFacetValues()
    }
  }

  /*
    Helper function
    Create an array of facets to use in a request object
  */
  const createElasticFacetArray = (extraFacetArray: Array<string | object> = []) => {
    const showFacets = extraFacetArray.length > 0 ? extraFacetArray : showAllFacetsArray
    const facets: FacetsObjectInterface = {}

    showFacets.forEach((item) => {
      if (typeof item === 'string') {
        facets[item] = [
          {
            type: 'value',
            size: 50,
            sort: { value: 'asc' },
          },
        ]
      } else {
        Object.values(item).forEach((nestedFacet) => {
          nestedFacet.forEach((facetObj: StaticFacetsInterface) => {
            facets[facetObj.facetName] = [
              {
                type: 'value',
                size: 50,
                sort: { value: 'asc' },
              },
            ]
          })
        })
      }
    })

    return facets
  }

  // --------------------------------
  // ERRORS
  // --------------------------------

  /*
    Sets search errors in the search store

    RETURNS: void
  */
  const setSearchErrors = (errorMessage: string) => {
    // check if this error message already exist
    if (!searchErrors.value.map((error: any) => error.message).includes(errorMessage)) {
      searchErrors.value.push({
        message: errorMessage,
      })
    }
  }

  /*
    Clears the search errors in the search store

    RETURNS: void
  */
  const clearSearchErrors = () => {
    searchErrors.value = []
  }

  /*
    Check the different Axios responses and set errors

    RETURNS: void
  */
  const handleAxiosError = (error: any) => {
    // don't show request aborted
    // if users search for direct productnumber and they hit enter while the typeahead is still searching
    // these requests are aborted due to a redirect
    // please ignore this error message
    if (error.message === 'Request aborted') {
      searchStore.changeLoader(false)
      return
    }

    // Send the error to the error middleware so that it is captured in CloudWatch from the ArgoCD server logs
    // Add the name property to the error
    const logName = { type: 'ElasticSearchAxiosError' }
    const newError = { ...error, ...logName }
    $logService.sendError(newError)

    if (error.code === 'ECONNABORTED') {
      setSearchErrors(
        'There was an error processing the search results. Please wait a few seconds before you try again.'
      )
      searchStore.changeLoader(false)
      return
    }

    if (error.response && error.response.data) {
      const errorArray = Array.isArray(error.response.data)
        ? error.response.data[0]?.errors
        : error.response.data?.errors

      if (errorArray !== undefined) {
        errorArray.forEach((error: string) => setSearchErrors(error))
      }
      searchStore.hideSuggests()
    } else if (error.message) {
      setSearchErrors(error.message)
      searchStore.hideSuggests()
    } else if (error.request) {
      setSearchErrors(error.request)
      searchStore.hideSuggests()
    }
    // After setting the errors, change loader
    searchStore.changeLoader(false)
  }

  return {
    storedSearchInput,
    storedTypeaheadSearchInput,
    loadingSuggests,
    prettyTypeaheadName,
    searchErrors,
    infoPanelsOpen,
    infoPanelOpenIDs,
    getSearchResultUrl,
    setDefaultSearchSettings,
    performSearch,
    performAlternativeSearch,
    typeaheadGetAll,
    getHistorySuggests,
    storeCachedFacets,
    getRedirectProductNumber,
  }
}
